import React, { useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { LEVELS } from '../../../../utils/constants/category-levels';
import { IAssetTemplate } from '../../../../interfaces/asset-templates/IAssetTemplate';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { IUIActionAsOption } from '../../../../interfaces/ui-actions/IUIAction';
import { IPayServices } from '../../../../interfaces/pay-services/IPayServices';
import { tryToFetchAllPayServices } from '../../../../store/brokers/admin/pay-services/payServicesSlice';
import { tryToFetchAllUIActions } from '../../../../store/brokers/admin/ui-actions/uiActionsSlice';
import {
    tryToAddAssetTemplate,
    tryToEditAssetTemplate,
    tryToFetchAssetFieldsTemplate,
    tryToFetchParentCategoriesByLevel,
    tryToFetchSingleAssetTemplate
} from '../../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Loading from '../../../../shared/Loading';
import Select from '../../../../shared/select';
import Button from '../../../../shared/button';
import Input from '../../../../shared/input';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Switch from '../../../../shared/switch';


interface ICreateAssetModal {
    openCreateAssetModal: boolean;
    assetItemId?: string | null;
    handleCloseAssetModal: () => void;
    onSaveChanges: (type: string, message: string) => void;
}

type FormValues = {
    name: string;
    displayName: string;
    minCashout: number;
    maxCashout: number;
    minPrice: number;
    maxPrice: number;
    maxValuePercentage?: number;
    minValuePercentage?: number;
    carbonImpact: number;
    services?: Array<{ id: string, name: string; }>;
    level: number;
    parent?: string;
    sortId?: number;
    backgroundColor?: string;
    targets?: Array<string>;
    uiActions: string;
};

const AssetTemplateModal = ({
    openCreateAssetModal,
    assetItemId,
    handleCloseAssetModal,
    onSaveChanges,
}: ICreateAssetModal) => {
    const dispatch = useAppDispatch();
    const [catalogFields, setCatalogFields] = useState<any>();
    const [appIcon, setAppIcon] = useState<any>();
    const [services, setServices] = useState<any>();
    const [enabledIcon, setEnabledIcon] = useState<any>();
    const [disabledIcon, setDisabledIcon] = useState<any>();
    const [generalError, setGeneralError] = useState<string>();
    const [selectedTargets, setSelectedTargets] = useState<any>();
    const [uiActions, setUIActions] = useState<IUIActionAsOption[]>();
    const [servicesSelected, setServicesSelected] = useState<Array<ISelectedValue & { id: string }>>();
    const [assetTemplateData, setAssetTemplateData] = useState<IAssetTemplate>();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const [enabledToGenerateAssets, setEnabledToGenerateAssets] = useState<boolean>(false)
    const state = useAppSelector((state) => state.assetsTemplates);
    const stateUIActions = useAppSelector((state) => state.uiActions.allUiActions);
    const parentCategories = useAppSelector((state) => state.assetsTemplates.parentCategories);
    const assetLoading = useAppSelector((state) => state.assetsTemplates?.assetTemplateIsLoading);

    const onDrop = useCallback((acceptedFiles: any) => {
        setEnabledIcon(acceptedFiles?.[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const { getRootProps: getRootPropsActive, getInputProps: getInputPropsActive } = useDropzone({
        onDrop: (acceptedFile) => {
            setDisabledIcon(acceptedFile[0]);
        },
    });

    const { getRootProps: getRootPropsApp, getInputProps: getInputPropsApp } = useDropzone({
        onDrop: (acceptedFile) => {
            setAppIcon(acceptedFile[0]);
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        resetField,
        watch,
    } = useForm<FormValues>({});

    useEffect(() => {
        if (state?.assetTemplateDetails && assetItemId) {
            setAssetTemplateData(state?.assetTemplateDetails)
        } else {
            setAssetTemplateData(undefined)
        }
        if (assetItemId) {
            setValue('name', assetItemId ? state?.assetTemplateDetails?.name || '' : '')
            setValue('displayName', assetItemId ? state?.assetTemplateDetails?.displayName || '' : '')
            assetItemId && state?.assetTemplateDetails?.maxCashout && setValue('maxCashout', state?.assetTemplateDetails?.maxCashout)
            assetItemId && state?.assetTemplateDetails?.minCashout && setValue('minCashout', state?.assetTemplateDetails?.minCashout)
            assetItemId && state?.assetTemplateDetails?.minPrice && setValue('minPrice', state?.assetTemplateDetails?.minPrice)
            assetItemId && state?.assetTemplateDetails?.maxPrice && setValue('maxPrice', state?.assetTemplateDetails?.maxPrice)
            assetItemId && state?.assetTemplateDetails?.carbonImpact && setValue('carbonImpact', state?.assetTemplateDetails?.carbonImpact)
            // assetItemId && state?.assetTemplateDetails?.enabledIcon && setValue('enabledIcon', state?.assetTemplateDetails?.enabledIcon)
            // assetItemId && state?.assetTemplateDetails?.disabledIcon && setValue('disabledIcon', state?.assetTemplateDetails?.disabledIcon)
            assetItemId && (state?.assetTemplateDetails?.level || state?.assetTemplateDetails?.level === 0) && setValue('level', state?.assetTemplateDetails?.level)
            assetItemId && state?.assetTemplateDetails?.uiActions && setValue('uiActions', state?.assetTemplateDetails?.uiActions?.name)
            assetItemId && state?.assetTemplateDetails?.deductionValues?.minValuePercentage ? setValue('minValuePercentage', state?.assetTemplateDetails?.deductionValues?.minValuePercentage) : resetField('minValuePercentage')
            assetItemId && state?.assetTemplateDetails?.deductionValues?.maxValuePercentage ? setValue('maxValuePercentage', state?.assetTemplateDetails?.deductionValues?.maxValuePercentage) : resetField('maxValuePercentage')
            assetItemId && state?.assetTemplateDetails?.services ? setValue('services', state?.assetTemplateDetails?.services) : resetField('services')
            assetItemId && state?.assetTemplateDetails?.sortId ? setValue('sortId', state?.assetTemplateDetails?.sortId) : resetField('sortId')
            assetItemId && state?.assetTemplateDetails?.backgroundColor ? setValue('backgroundColor', state?.assetTemplateDetails?.backgroundColor) : resetField('backgroundColor')
            assetItemId && state?.assetTemplateDetails?.enabledToGenerateAssets && setEnabledToGenerateAssets(state?.assetTemplateDetails?.enabledToGenerateAssets)
            if (state?.assetTemplateDetails?.targets) {
                setSelectedTargets(state?.assetTemplateDetails?.targets)
            }
        } else {
            reset();
            setValue('uiActions', '')
            setAppIcon(undefined)
            setEnabledIcon(undefined)
            setDisabledIcon(undefined)
            setAssetTemplateData(undefined)
            setSelectedTargets(undefined)
            setEnabledToGenerateAssets(false)
        }
    }, [state.assetTemplateDetails, assetItemId, openCreateAssetModal]);

    const getPayServicesData = async () => {
        const payServicesResponse = await dispatch(tryToFetchAllPayServices()).unwrap()
        const formatPayServices = (payServicesResponse && payServicesResponse?.data && payServicesResponse?.data?.length > 0)
            && payServicesResponse?.data?.map((service: IPayServices) => ({ ...service, label: service?.displayName, value: service?._id }))
        setServices(formatPayServices || [])
    }

    useEffect(() => {
        getPayServicesData()
    }, [])

    useEffect(() => {
        if (state.assetTemplateDetails?.parent && assetItemId && parentCategories && openCreateAssetModal) {
            const findParent = parentCategories.find((parent: any) => parent.name === state.assetTemplateDetails?.parent?.name)
            if (findParent) {
                state?.assetTemplateDetails?.parent && setValue('parent', state?.assetTemplateDetails?.parent.name)
            }
        } else {
            resetField('parent')
        }
    }, [state.assetTemplateDetails?.parent, openCreateAssetModal, assetItemId, parentCategories])

    useEffect(() => {
        if (assetItemId) {
            dispatch(tryToFetchSingleAssetTemplate(assetItemId));
            dispatch(tryToFetchAssetFieldsTemplate(assetItemId));
        }
    }, [assetItemId])

    useEffect(() => {
        if (state.assetTemplateFields) {
            const rows =
                state.assetTemplateFields && Array.isArray(state.assetTemplateFields)
                    ? state.assetTemplateFields?.filter((r) => r?.enabled).map((r) => ({ name: r?.name, label: r?.name, value: r?.name }))
                    : [];
            setCatalogFields(rows)
        }
    }, [state.assetTemplateFields]);

    const watchLevel = watch('level');
    const watchUIAction = watch('uiActions')

    useEffect(() => {
        if (Number(watchLevel) > -1) {
            dispatch(tryToFetchParentCategoriesByLevel((Number(watchLevel) - 1).toString())).unwrap();
        }
    }, [watchLevel]);

    useEffect(() => {
        const filters = { search: '' }
        dispatch(tryToFetchAllUIActions(filters)).unwrap()
    }, [openCreateAssetModal])

    useEffect(() => {
        if (stateUIActions?.data) {
            setUIActions(stateUIActions?.data?.map((uiActionEl: any) => {
                return { ...uiActionEl, label: uiActionEl.displayName, value: uiActionEl.name }
            }))
        }
    }, [stateUIActions])

    useEffect(() => {
        if (assetItemId && state?.assetTemplateDetails?.services && services) {
            const formatSelectedServices = (state?.assetTemplateDetails?.services && state?.assetTemplateDetails?.services?.length > 0) && state?.assetTemplateDetails?.services?.map((item: any) => {
                const findService = services && services?.length > 0 && services?.find((service: IPayServices) => service?._id === item?.id)
                return findService
            })
            setServicesSelected(formatSelectedServices || undefined)
        }
    }, [assetItemId, state?.assetTemplateDetails?.services, services])

    const onSubmit = async (data: FormValues) => {
        if (submitLoading) {
            return;
        }
        setSubmitLoading(true)
        let foundParent
        if (Number(watchLevel) > -1) {
            foundParent = parentCategories.find((parent: any) => parent.name === data.parent)
        }
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('displayName', data.displayName)
        formData.append('minCashout', JSON.stringify(Number(data.minCashout || 0)))
        formData.append('maxCashout', JSON.stringify(Number(data.maxCashout || 0)))
        formData.append('minPrice', JSON.stringify(Number(data.minPrice || 0)))
        formData.append('maxPrice', JSON.stringify(Number(data.maxPrice || 0)))
        formData.append('carbonImpact', JSON.stringify(Number(data.carbonImpact || 0)))
        formData.append('level', JSON.stringify(Number(data.level)))
        formData.append('backgroundColor', data?.backgroundColor || '')
        formData.append('sortId', JSON.stringify(Number(data?.sortId || 0)))
        formData.append('targets', JSON.stringify(selectedTargets || []))
        formData.append('enabledToGenerateAssets', JSON.stringify(enabledToGenerateAssets))
        if (data.minValuePercentage || data.maxValuePercentage) {
            const formatDeduction = {
                minValuePercentage: data?.minValuePercentage,
                maxValuePercentage: data?.maxValuePercentage
            }
            formData.append('deductionValues', JSON.stringify(formatDeduction))
        }
        if (data?.services && data?.services?.length > 0) {
            formData.append('services', JSON.stringify(data?.services))
        }
        if (data?.uiActions && data.uiActions !== '') {
            const foundUIAction = stateUIActions?.data?.find((action: any) => action.name === watchUIAction)
            const formUIActions = { name: foundUIAction?.name, displayName: foundUIAction?.displayName }
            formData.append('uiActions', JSON.stringify(formUIActions))
        }
        const formatParent = foundParent ? { id: foundParent._id || '', name: foundParent.name } : undefined
        if (data?.parent && formatParent) {
            formData.append('parent', JSON.stringify(formatParent))
        }
        if (enabledIcon) {
            formData.append('enabledIcon', enabledIcon)
        }
        if (disabledIcon) {
            formData.append('disabledIcon', disabledIcon)
        }

        if (appIcon) {
            formData.append('appIconUrl', appIcon)
        }
        try {
            if (assetItemId) {
                await dispatch(tryToEditAssetTemplate({ data: formData, id: assetItemId })).unwrap();
            } else {
                await dispatch(tryToAddAssetTemplate(formData)).unwrap();
            }
            onSaveChanges('success', `Asset Catalog successfully ${assetItemId ? 'changed' : 'added'}.`)
            reset();
            setEnabledIcon(undefined)
            setDisabledIcon(undefined)
            setAppIcon(undefined)
            setUIActions(undefined)
            setAssetTemplateData(undefined)
            handleCloseAssetModal()
            setServicesSelected(undefined)
        } catch (error) {
            setGeneralError(`${error}`)
        }
        setSubmitLoading(false)
    };

    const onCloseModal = () => {
        reset()
        setSubmitLoading(false)
        setEnabledIcon(undefined)
        setDisabledIcon(undefined)
        setAppIcon(undefined)
        setAssetTemplateData(undefined)
        setSelectedTargets(undefined)
        setUIActions(undefined)
        setServicesSelected(undefined)
        setEnabledToGenerateAssets(false)
        handleCloseAssetModal()
    }

    const onChangeSelectedTargets = (value: any, type?: string, index?: number) => {
        if (index || index === 0) {
            const formatTargets: any = selectedTargets ? [...selectedTargets] : [[]]
            if (formatTargets && formatTargets[index]) {
                formatTargets[index] = (value && value?.length > 0) ? value?.map((item: any) => item?.value) : []
                setSelectedTargets(formatTargets)
            } else {
                formatTargets.push((value && value?.length > 0) ? value?.map((item: any) => item?.value) : [])
                setSelectedTargets(formatTargets)
            }
        }
    }

    const onAddTargets = () => {
        setSelectedTargets([...selectedTargets || [], []])
    }

    const onDeleteTargets = (index: number) => {
        const formatTargets: any = (selectedTargets && selectedTargets?.length > 0) && selectedTargets?.filter((item: any, idx: number) => index !== idx)
        setSelectedTargets(formatTargets)
    }

    const onChangeSelectedServices = (e: any, type?: string, index?: number) => {
        const formatValue = e && e?.length > 0 && e?.map((item: ISelectedValue) => ({ id: item?.value, name: item?.name }))
        setValue('services', formatValue)
        setServicesSelected(e)
    }

    const onChangeGenerateAssetState = () => {
        setEnabledToGenerateAssets(!enabledToGenerateAssets)
    }

    return (
        <Modal
            open={openCreateAssetModal}
            showInRight={true}
            contentContainerStyle='!min-w-[650px]'
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[600px]'}>
                <p className='mt-2 mb-4 page-title !text-[18px] font-bold mr-10'>Asset Catalog</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {((assetItemId && !assetLoading) || !assetItemId) ?
                        <div className='flex flex-col my-4'>
                            <div className='flex flex-row gap-x-3 justify-items-stretch bg-[#f8f9fc] rounded p-2 mb-2'>
                                <Input
                                    placeholder='Display Name'
                                    dataQa={'add-asset-display-name'}
                                    label={assetItemId ? 'Display Name' : ''}
                                    register={register('displayName', {
                                        required: {
                                            message: 'Display Name is required',
                                            value: true,
                                        },
                                        validate: (value: string) => !!value.trim() || 'Display Name is required'
                                    })}
                                    error={errors.displayName?.message}
                                />
                                <Input
                                    placeholder='Name'
                                    dataQa={'add-asset-name'}
                                    disabled={!!assetItemId}
                                    label={assetItemId ? 'Name' : ''}
                                    register={register('name', {
                                        required: {
                                            message: 'Name is required',
                                            value: true,
                                        },
                                        validate: (value: string) => !!value.trim() || 'Name is required'
                                    })}
                                    error={errors.name?.message}
                                />
                                <Input
                                    placeholder='Carbon Impact'
                                    dataQa={'add-asset-carbon-impact'}
                                    label={assetItemId ? 'Carbon Impact' : ''}
                                    type='text'
                                    register={register('carbonImpact', {
                                        required: {
                                            message: 'Carbon Impact is required',
                                            value: true,
                                        },
                                        pattern: {
                                            value: /^-?[0-9]\d*\.?\d*$/,
                                            message: 'Only numbers are accepted.'
                                        },
                                        validate: (value: number) => (Number(value) >= 0) || 'Carbon Impact should be a positive number'
                                    })}
                                    error={errors.carbonImpact?.message}
                                />
                            </div>
                            <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                <p className='font-bold text-sm mb-3 mt-2'>Cashout</p>
                                <div className='flex flex-row gap-x-3 justify-items-stretch'>
                                    <Input
                                        placeholder='Min Cashout'
                                        dataQa={'add-asset-min-cashout'}
                                        label={assetItemId ? 'Min Cashout' : ''}
                                        type='text'
                                        register={register('minCashout', {
                                            required: {
                                                message: 'Min Cashout is required',
                                                value: true,
                                            },
                                            pattern: {
                                                value: /^-?[0-9]\d*\.?\d*$/,
                                                message: 'Only numbers are accepted.'
                                            },
                                            validate: (value: number) => (Number(value) >= 0) || 'Min Cashout should be a positive number'
                                        })}
                                        error={errors.minCashout?.message}
                                    />
                                    <Input
                                        placeholder='Max Cashout'
                                        dataQa={'add-asset-max-cashout'}
                                        label={assetItemId ? 'Max Cashout' : ''}
                                        type='text'
                                        register={register('maxCashout', {
                                            required: {
                                                message: 'Max Cashout is required',
                                                value: true,
                                            },
                                            pattern: {
                                                value: /^-?[0-9]\d*\.?\d*$/,
                                                message: 'Only numbers are accepted.'
                                            },
                                            validate: (value: number) => (Number(value) >= 0) || 'Max Cashout should be a positive number'
                                        })}
                                        error={errors.maxCashout?.message}
                                    />
                                </div>
                            </div>
                            <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                <p className='font-bold text-sm mb-3 mt-2'>Price</p>
                                <div className='flex flex-row gap-x-3 justify-items-stretch'>
                                    <Input
                                        placeholder='Min Price'
                                        dataQa={'add-asset-min-price'}
                                        label={assetItemId ? 'Min Price' : ''}
                                        type='text'
                                        register={register('minPrice', {
                                            required: {
                                                message: 'Min Price is required',
                                                value: true,
                                            },
                                            pattern: {
                                                value: /^-?[0-9]\d*\.?\d*$/,
                                                message: 'Only numbers are accepted.'
                                            },
                                            validate: (value: number) => (Number(value) >= 0) || 'Min Price should be a positive number'
                                        })}
                                        error={errors.minPrice?.message}
                                    />
                                    <Input
                                        placeholder='Max Price'
                                        dataQa={'add-asset-max-price'}
                                        label={assetItemId ? 'Max Price' : ''}
                                        type='text'
                                        register={register('maxPrice', {
                                            required: {
                                                message: 'Max Price is required',
                                                value: true,
                                            },
                                            pattern: {
                                                value: /^-?[0-9]\d*\.?\d*$/,
                                                message: 'Only numbers are accepted.'
                                            },
                                            validate: (value: number) => (Number(value) >= 0) || 'Max Price should be a positive number'
                                        })}
                                        error={errors.maxPrice?.message}
                                    />
                                </div>
                                <p className='font-bold text-sm mb-3 mt-2'>Icons</p>
                                <div>
                                    <div
                                        className='flex flex-row items-center'
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <Button
                                            icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                                            className={'btn-main !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                                            label={'Upload Enabled Icon'}
                                            dataQa={'select-image'}
                                        />
                                        {enabledIcon && enabledIcon?.name
                                            ? <p className={'ml-3'}>{enabledIcon?.name}</p>
                                            : assetTemplateData?.enabledIcon ? <img alt='Section Icon' src={assetTemplateData?.enabledIcon} className={'w-[40px] mx-3'} /> : <></>
                                        }
                                    </div>
                                    <div
                                        className='flex flex-row items-center mt-5 mb-4'
                                        {...getRootPropsActive()}
                                    >
                                        <input {...getInputPropsActive()} />
                                        <Button
                                            icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                                            className={'btn-main !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                                            label={'Upload Disabled Icon'}
                                            dataQa={'select-active-image'}
                                        />
                                        {(disabledIcon && disabledIcon?.name)
                                            ? <p className={'ml-3'}>{disabledIcon?.name}</p>
                                            : assetTemplateData?.disabledIcon ? <img alt='Section Active Icon' src={assetTemplateData?.disabledIcon} className={'w-[40px] mx-3'} /> : <></>
                                        }
                                    </div>
                                </div>
                                <Select
                                    name='Level'
                                    placeholder=' '
                                    dataQa={'add-asset-level'}
                                    register={register('level', {
                                        required: {
                                            message: 'Level is required',
                                            value: true,
                                        }
                                    })}
                                    options={LEVELS}
                                    error={errors?.level?.message}
                                />
                                {
                                    Number(watchLevel) > -1 &&
                                    <>
                                        <Select
                                            name='Parent'
                                            placeholder=' '
                                            dataQa={'add-asset-parent'}
                                            register={register('parent')}
                                            options={parentCategories.map(parent => {
                                                return {
                                                    label: parent.displayName,
                                                    value: parent.name
                                                }
                                            })}
                                            error={errors?.parent?.message}
                                        />
                                    </>
                                }
                                <div className={'mt-3 mb-5'}>
                                    <Switch
                                        onToggleSwitch={onChangeGenerateAssetState}
                                        checked={enabledToGenerateAssets}
                                        beforeText={'Multiple Asset Creation'}
                                        dataQa={'assets-switch'}
                                    />
                                </div>
                                <Select
                                    name='UI Actions'
                                    placeholder=' '
                                    dataQa={'add-asset-ui-actions'}
                                    register={register('uiActions')}
                                    options={uiActions || []}
                                    error={errors?.uiActions?.message}
                                />
                                {[...Array(selectedTargets?.length || 1)]?.map((item: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className='flex flex-row justify-between items-center'>
                                            <SelectCheckbox
                                                name='Targets'
                                                dataQa={'targets'}
                                                placeholder=' '
                                                multiple={true}
                                                containerStyle='max-w-[82%] w-[82%]'
                                                onChangeSelectedOption={onChangeSelectedTargets}
                                                index={index}
                                                selectedOption={(selectedTargets?.[index] && selectedTargets?.[index]?.length > 0) ? selectedTargets?.[index]?.map((item: string) => ({ name: item, value: item, label: item })) : undefined}
                                                options={catalogFields || []}
                                            />
                                            <div className='cursor-pointer' onClick={() => onDeleteTargets(index)}>
                                                <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] ml-4 hover:w-[17px] object-contain'} />
                                            </div>
                                            {index === 0 &&
                                                <Button
                                                    label={'+'}
                                                    dataQa={'add-button'}
                                                    className={`btn-main rounded ml-2 mt-2`}
                                                    onClickButton={onAddTargets}
                                                />
                                            }
                                        </div>
                                    )
                                })}
                                <div
                                    className='flex flex-row items-center'
                                    {...getRootPropsApp()}
                                >
                                    <input {...getInputPropsApp()} />
                                    <Button
                                        icon={<img src='/assets/shared/add-plus.svg' className='w-[30px] object-contains pr-2' />}
                                        className={'btn-main !bg-white !py-2 !pr-3 !shadow-none !border-none flex flex-row items-center'}
                                        label={'Upload App Icon'}
                                        dataQa={'select-app-icon'}
                                    />
                                    {appIcon && appIcon?.name
                                        ? <p className={'ml-3'}>{appIcon?.name}</p>
                                        : assetTemplateData?.appIconUrl ? <img alt='Section Icon' src={assetTemplateData?.appIconUrl} className={'w-[40px] mx-3'} /> : <></>
                                    }
                                </div>
                            </div>
                            <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                <SelectCheckbox
                                    name='Services'
                                    placeholder=' '
                                    dataQa={'add-asset-services'}
                                    multiple={true}
                                    selectedOption={servicesSelected}
                                    options={services || []}
                                    error={errors?.services?.message}
                                    onChangeSelectedOption={onChangeSelectedServices}
                                />
                            </div>
                            <div className='flex flex-row gap-x-3 justify-items-stretch bg-[#f8f9fc] rounded p-2 mb-2'>
                                <Input
                                    placeholder='Min Pricing Percentage'
                                    dataQa={'add-asset-min-percentage'}
                                    label={assetItemId ? 'Min Pricing Percentage' : ''}
                                    type='text'
                                    register={register('minValuePercentage', {
                                        pattern: {
                                            value: /^-?[0-9]\d*\.?\d*$/,
                                            message: 'Only numbers are accepted.'
                                        },
                                    })}
                                    error={errors.minValuePercentage?.message}
                                />
                                <Input
                                    placeholder='Max Pricing Percentage'
                                    dataQa={'add-asset-max-price'}
                                    label={assetItemId ? 'Max Pricing Percentage' : ''}
                                    type='text'
                                    register={register('maxValuePercentage', {
                                        pattern: {
                                            value: /^-?[0-9]\d*\.?\d*$/,
                                            message: 'Only numbers are accepted.'
                                        }
                                    })}
                                    error={errors.maxValuePercentage?.message}
                                />
                            </div>
                            <div className='bg-[#f8f9fc] rounded p-2 mb-2'>
                                <Input
                                    placeholder='Background Color'
                                    dataQa={'add-asset-background-colos'}
                                    label={assetItemId ? 'Background Color' : ''}
                                    register={register('backgroundColor')}
                                    error={errors.backgroundColor?.message}
                                />
                                <Input
                                    placeholder='Sort ID'
                                    dataQa={'add-asset-sort-id'}
                                    label={assetItemId ? 'Sort ID' : ''}
                                    type='text'
                                    register={register('sortId', {
                                        pattern: {
                                            value: /^-?[0-9]\d*\.?\d*$/,
                                            message: 'Only numbers are accepted.'
                                        }
                                    })}
                                    error={errors.sortId?.message}
                                />
                            </div>
                            {generalError && <Error text={generalError} />}
                            <div className='flex flex-row justify-end mt-4'>
                                <Button
                                    label='Save'
                                    type={'submit'}
                                    className={submitLoading ? 'btn-primary-disable' : 'btn-primary'}
                                    dataQa={'add-asset-save-btn'}
                                />
                            </div>
                        </div>
                        : <div className='my-3 flex flex-row justify-center items-center'>
                            <Loading />
                        </div>
                    }
                </form>
            </div>
        </Modal>
    )
}
export default AssetTemplateModal;
