import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ASSET_STATUSES } from '../../../utils/constants/asset-statuses';
import {
    tryToAddAsset,
    tryToEditAsset,
    tryToFetchSingleAsset
} from '../../../store/brokers/admin/assets/assetsSlice';
import {
    tryToFetchAssetFieldsTemplate,
    tryToFetchSingleAssetTemplate
} from '../../../store/brokers/admin/asset-templates/assetsTemplatesSlice';
import { IAssetFields } from '../../../interfaces/asset-templates/IAssetTemplate';
import { IAssets } from '../../../interfaces/assets/IAssets';
import SelectCheckbox from '../../../shared/select-checkbox';
import Button from '../../../shared/button';
import Input from '../../../shared/input';
import Error from '../../../shared/error';


const CreateAsset = () => {
    const { id, assetId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<any>();
    const [generalError, setGeneralError] = useState<string>();
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [assetFields, setAssetFields] = useState<IAssetFields[]>([]);
    const [generate, setGenerate] = useState<boolean>(false);
    const stateAssetFields = useAppSelector((state) => state.assetsTemplates);
    const assetDetails = useAppSelector((state) => state.assets?.assetsDetails);
    const assetTemplate = useAppSelector((state) => state.assetsTemplates?.assetTemplateDetails);

    useEffect(() => {
        if (id) {
            dispatch(tryToFetchAssetFieldsTemplate(id));
            dispatch(tryToFetchSingleAssetTemplate(id));
        }
    }, []);

    useEffect(() => {
        if (assetId) {
            const data = {
                id,
                assetId
            }
            dispatch(tryToFetchSingleAsset(data))
        } else {
            setFormData(undefined)
        }
    }, [assetId])

    useEffect(() => {
        if (assetDetails && assetId) {
            const formatDescriptorKeys = assetDetails?.descriptor && Object?.keys(assetDetails?.descriptor)
            let formatDescriptor: any = {}
            formatDescriptorKeys && formatDescriptorKeys?.length > 0 && formatDescriptorKeys?.map((item: any) => {
                const formatValue = { value: assetDetails?.descriptor?.[item], label: assetDetails?.descriptor?.[item] }
                formatDescriptor = {
                    ...formatDescriptor || {},
                    [item]: formatValue
                }
                return item;
            })
            setFormData(formatDescriptor)
            const findStatus = ASSET_STATUSES?.find((item: any) => item?.value === (assetDetails?.status || 'enabled'))
            setSelectedStatus(findStatus)
        } else {
            setFormData(undefined)
        }
    }, [assetDetails])

    useEffect(() => {
        if (stateAssetFields.assetTemplateFields) {
            const rows =
                stateAssetFields.assetTemplateFields &&
                    Array.isArray(stateAssetFields.assetTemplateFields)
                    ? stateAssetFields.assetTemplateFields
                        ?.filter((r) => r?.enabled)
                        .map((r) => ({ ...r, id: r.id }))
                    : [];
            setAssetFields(rows);
        }
    }, [stateAssetFields.assetTemplateFields]);

    const onSubmit = async () => {
        if (!selectedStatus) {
            setGeneralError('Please select status')
            return;
        }
        let formatDescriptor: any = {};
        const formatKeys = formData && Object?.keys(formData)
        if (generate) {
            formatKeys && formatKeys?.length > 0 && formatKeys?.forEach((item: any) => {
                const formatFieldsSelected = formData?.[item] && formData?.[item]?.length > 0 && formData?.[item]?.map((field: any) => field?.value)
                formatDescriptor = {
                    ...formatDescriptor || {},
                    [item]: formatFieldsSelected
                }
            })
        } else {
            formatKeys && formatKeys?.length > 0 && formatKeys?.forEach((item: any) => {
                const formatFieldsSelected = formData?.[item] && formData?.[item]?.value
                formatDescriptor = {
                    ...formatDescriptor || {},
                    [item]: formatFieldsSelected
                }
            })
        }
        const data: IAssets = {
            assetTemplateId: id || '',
            generate: generate,
            descriptor: formatDescriptor,
            status: selectedStatus?.value
        };
        try {
            if (assetId) {
                data._id = assetId
                await dispatch(tryToEditAsset(data)).unwrap()
            } else {
                await dispatch(tryToAddAsset(data)).unwrap();
            }
            setGeneralError(undefined);
            setFormData(undefined);
            navigate(`/assets/${id}`);
        } catch (err) {
            setGeneralError(`${err}`);
        }
    };

    const onChangeInput = (value: string | null | undefined, type: string) => {
        const findField = assetFields?.find((field) => field?.name === type);
        setFormData({
            ...(formData || {}),
            [type]:
                findField?.dataType === 'number' ? Number(value || 0) : value,
        });
    };

    const onChangeSelectedValue = (e: any, type?: string) => {
        if (type) {
            if (e?.[0]?.value === 'all') {
                const assetValues = assetFields?.find((field) => field?.name === type)?.values;
                const formatAllData = assetValues && assetValues?.length > 0 && assetValues?.map((item: any) => ({ value: item, label: item }))
                setFormData({
                    ...(formData || {}),
                    [type]: formatAllData
                })
            } else {
                const findField = assetFields?.find((field) => field?.name === type);
                setFormData({
                    ...(formData || {}),
                    [type]: findField?.dataType === 'boolean' ? e?.value === 'true' : e || undefined,
                });
            }
        }
    };

    const onDiscardChanges = () => {
        navigate(`/assets/${id}`);
    };

    const onChangeSelectedStatus = (value: any, type?: string) => {
        setSelectedStatus(value)
    }

    const onClickGenerate = () => {
        setGenerate(!generate)
        setFormData(undefined)
    }

    const onClearFields = () => {
        setFormData(undefined)
    }

    return (
        <div>
            <div className='mb-4'>
                <p className='page-title'>{assetId ? 'Edit Asset' : 'Create Asset'}</p>
                {generalError && <Error text={generalError} />}
            </div>
            {assetTemplate?.enabledToGenerateAssets && !assetId &&
                <div>
                    <div className='flex flex-row items-center mb-6'>
                        <div className='flex items-center mr-2'>
                            <input
                                onChange={() => onClickGenerate()}
                                checked={generate}
                                id='checkbox-all'
                                data-qa={'checkbox'}
                                type='checkbox'
                                className='w-4 h-4 !outline-none text-blue-600 bg-gray-100 border-gray-300 accent-yellow-300 rounded focus:accent-yellow-300 focus:ring-2'
                            />
                            <label htmlFor='checkbox-all' className='sr-only'>
                                checkbox
                            </label>
                        </div>
                        <p>
                            Generate asset items based on selections
                        </p>
                    </div>
                </div>
            }
            <div className='grid grid-cols-4 gap-4 mt-6'>
                {assetFields?.length > 0 &&
                    assetFields?.map((field, idx) => {
                        let values: any = field?.values;
                        if (field?.dataType === 'boolean') {
                            values = [
                                { value: true, label: 'True' },
                                { value: false, label: 'False' },
                            ];
                        } else if (field?.dataType === 'list') {
                            values =
                                field?.values &&
                                field?.values?.length > 0 &&
                                values?.map((value: string) => {
                                    return { value: value, label: value };
                                });
                        }
                        return (
                            <>
                                {field?.dataType === 'number' ||
                                    field?.dataType === 'string' ? (
                                    <Input
                                        key={idx}
                                        containerStyle='mb-0'
                                        label={field?.name && `${field?.name[0].toUpperCase()}${field?.name?.slice(1)?.toLowerCase()}`}
                                        showValue={true}
                                        type={field?.dataType}
                                        placeholder={' '}
                                        inputValue={formData?.[field?.name]}
                                        onChangeInput={onChangeInput}
                                        inputUniqueName={field?.name}
                                    />
                                ) : (
                                    <SelectCheckbox
                                        key={idx}
                                        name={field?.name && `${field?.name[0].toUpperCase()}${field?.name.slice(1).toLowerCase()}`}
                                        containerStyle='mb-1'
                                        selectedOption={formData?.[field?.name] ? formData?.[field?.name] : undefined}
                                        placeholder={' '}
                                        uniqueName={field?.name}
                                        multiple={generate && field?.dataType !== 'boolean'}
                                        onChangeSelectedOption={onChangeSelectedValue}
                                        options={values && generate && field?.dataType !== 'boolean' ? [{ value: 'all', label: 'All' }, ...values || []] : values}
                                    />
                                )}
                            </>
                        );
                    })}
                <SelectCheckbox
                    name={'Status'}
                    placeholder=' '
                    options={ASSET_STATUSES}
                    selectedOption={selectedStatus}
                    onChangeSelectedOption={onChangeSelectedStatus}
                />
            </div>
            <div className='flex flex-row justify-start my-6'>
                <Button
                    label='Save'
                    dataQa={'create-asset-save-btn'}
                    onClickButton={() => onSubmit()}
                    className={'btn-primary !shadow-none min-w-[150px] mr-4 !rounded'}
                />
                <Button
                    label='Discard'
                    className='btn-main !rounded min-w-[130px] mr-4'
                    onClickButton={onDiscardChanges}
                    dataQa={'create-asset-discard-btn'}
                />
                <Button
                    label='Clear Fields'
                    className='btn-categories !rounded min-w-[130px]'
                    onClickButton={onClearFields}
                    dataQa={'clear-fields-btn'}
                />
            </div>
        </div>
    );
};

export default CreateAsset;
